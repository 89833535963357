<template>
  <!-- 问卷调查 -->
  <div class="questionnaire">
    <Bscroll
      class="scroll"
      ref="scroll"
    >
      <van-form>
        <div
          class="investigation"
          v-for="item in List"
          :key="item.key"
          ref="scrolla"
        >
          <!-- 标题 -->
          <div
            class="investigationitem"
            v-if="item.type == 'bt'"
          >
            <h1>{{item.title}}</h1>
            <h3>{{item.option}}</h3>
          </div>
          <!-- 单选框 -->
          <div
            class="investigationitem"
            v-if="item.type == 'dxk'"
          >
            <div class="title">{{item.sort+'.' + item.title}}</div>
            <van-radio-group
              class="danxuan"
              v-model="item.value"
              v-for="item2 in item.option"
              :key="item2.key"
            >
              <van-radio
                class="dxitem"
                :name="item2.value"
              >{{item2.value}}</van-radio>
            </van-radio-group>
          </div>
          <!-- 复选框 -->
          <div
            class="investigationitem"
            v-if="item.type == 'dxk2'"
          >
            <div class="title">{{item.sort+'.' + item.title}}</div>
            <van-checkbox-group
              class="fuxuan"
              v-model="item.value"
              v-for="item2 in item.option"
              :key="item2.key"
            >
              <van-checkbox
                class="fxitem"
                :name="item2.value"
                shape="square"
              >{{item2.value}}</van-checkbox>
            </van-checkbox-group>
          </div>
          <!-- 输入框 -->
          <div
            class="investigationitem"
            v-if="item.type == 'inp'"
          >
            <div class="title">{{item.sort+'.' + item.title}}</div>
            <van-field
              class="shuru"
              v-model="item.value"
              placeholder="请输入内容"
              style="border:2px solid #e2ebf0;border-radius:7px"
            />
          </div>
          <!-- 文本域 -->
          <div
            class="investigationitem"
            v-if="item.type == 'wbk'"
          >
            <div class="title">{{item.sort+'.' + item.title}}</div>
            <van-field
              class="shuru"
              v-model="item.value"
              rows="2"
              autosize
              type="textarea"
              maxlength="200"
              placeholder="请输入内容"
              show-word-limit
              contenteditable="true"
              style="border:2px solid #e2ebf0;border-radius:7px"
            />
          </div>
          <!-- 评分 -->
          <div
            class="investigationitem"
            v-if="item.type == 'pf'"
          >
            <div class="title">{{item.sort+'.' + item.title}}</div>
            <div class="pingfen">
              <van-rate
                color="#ffd21e"
                v-model="item.value"
                @change="change(item)"
              />
              <div class="degree">{{item.satisfaction}}</div>
            </div>
          </div>
        </div>
        <div
          class="investigationitem1"
          v-if="show == 1"
        >
          <van-button
            class="submit"
            size="large"
            round
            @click="submit"
          >提交</van-button>
        </div>
      </van-form>

    </Bscroll>
  </div>
</template>

<script>
import Bscroll from '@/components/content/scroll/BScroll'
import { feedback, submitPost } from "@/network/service.js";
export default {
  name: 'questionnaire',
  data () {
    return {
      List: [],
      title: '',
      show: 0,
      satisfaction: ''
    }
  },
  mounted () {
    this.feedbackPost()
    document.title = this.$route.query.title
  },
  components: {
    Bscroll,
  },
  methods: {
    async feedbackPost () {
      let res = await feedback({
        id: this.$route.query.id
      })
      if (res.code == "0") {
        if (res.data.stat == "1") {
          this.List = JSON.parse(res.data.content)
          this.List.map(item => {
            item.satisfaction = ''
          })
          this.title = res.data.title
          this.show = 1
          this.$nextTick(() => {
            this.$refs.scroll.refresh()
          })
        } else {
          let data = this.$router.options.routes.find(item => item.redirect != undefined)
          this.$router.push(data.redirect)
          this.$toast(res.msg)
        }
      } else {
        this.$toast(res.msg)
      }
    },
    async submit () {
      let list = this.List.filter(el => el.type != 'bt')
      let array = list.map(item => {
        return {
          value: item.value,
          pid: item.pid,
          sid: item.sid
        }
      })
      let arr = []
      for (let val of array) {
        arr.push(val.value)
      }
      let index = arr.findIndex(el => el.length == 0)
      if (index != -1 && list.filter(el => el.type == "wbk").map(el => el.value.trim()).every(el => el.length !== 0) && list[index].required == "true") {
        this.$refs.scroll.scrollToElement(this.$refs.scrolla[index], 700)
        this.$toast("您有题目未完成")
      } else {
        await submitPost({
          commit_content: JSON.stringify(array),
          open_id: this.$store.state.cardList[0].openId,
          title: this.title
        })
        this.$router.push({ path: '/complete', query: { title: this.$route.query.title } })
      }
    },
    change (sort) {
      if (sort.value == 1) {
        sort.satisfaction = "非常不满意"
      }
      else if (sort.value == 2) {
        sort.satisfaction = "不满意"
      }
      else if (sort.value == 3) {
        sort.satisfaction = "一般"
      }
      else if (sort.value == 4) {
        sort.satisfaction = "满意"
      }
      else {
        sort.satisfaction = "非常满意"
      }
    }
  },
}
</script>

<style lang="less" scoped>
/deep/.van-field__control {
  -webkit-user-select: auto !important;
}
.questionnaire {
  background: #f5f5f5;
  .scroll {
    height: calc(100vh - 3rem);
    overflow: hidden;
    .investigation {
      background: rgb(255, 255, 255);
      .investigationitem {
        margin: 10px 10px;
        padding: 10px 0;
      }
      .danxuan {
        margin-top: 5px;
        .dxitem {
          margin-top: 3px;
          margin-left: 10px;
        }
      }
      .fuxuan {
        margin-top: 5px;
        .fxitem {
          margin-top: 3px;
          margin-left: 10px;
        }
      }
      .shuru {
        margin: 5px 5px 5px 0px;
      }
      .pingfen {
        margin-top: 3px;
        margin-left: 10px;
        display: flex;
        .degree {
          margin-left: 10px;
        }
      }
    }
    .investigationitem1 {
      margin: 15px 10px;
      .submit {
        background-color: @darkColor;
        color: #ffffff;
      }
    }
  }
}
</style>